.custom-quill-theme .ql-editor::before {
    color: #aaa;
    font-style: italic;
}

.custom-quill-theme .ql-editor {
    min-height: 200px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #fff;
    padding: 15px;
    border-radius: 10px;
    border: 1.5px solid #8cc63f;
    background-color: #333;
    margin: 0;
    box-shadow: none;
}

/* Ensure no extra border or padding around the container */
.custom-quill-theme .ql-container {
    border: 2px solid #8cc63f;
    border-radius: 13px;
    padding: 0;
    background-color: #333;
    margin: 0;
}

/* Customize the toolbar */
.custom-quill-theme .ql-toolbar {
    border: 2px solid #8cc63f;
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: #333;
    padding: 15px;
}

/* Customize buttons in the toolbar */

.custom-quill-theme .ql-toolbar button {
    border: 1px solid #8cc63f;
    border-radius: 4px;
    margin: 2px;
    background-color: grey;
    height: 30px;
    width: 35px;
}

.custom-quill-theme .ql-toolbar button:hover {
    border: 1px solid #fff;
    border-radius: 4px;
    margin: 2px;
    background-color: #8cc63f;
    height: 35px;
    width: 40px;
    filter: brightness(1.2);
}

/* Change the appearance of active buttons */
.custom-quill-theme .ql-toolbar button.ql-active {
    background-color: #8cc63f;
    border-color: #fff;
}

/* Customize italic text color */
.custom-quill-theme .ql-editor em {
    color: #8cc63f;
}

/* Customize link text color */
.custom-quill-theme .ql-editor a {
    color: #8cc63f;
}
