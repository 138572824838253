.pdfViewerContainer .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pdfViewerContainer .react-pdf__Page {
    margin: 1em 0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
