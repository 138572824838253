body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* generated global stylesheet */
:root {
    --mui-palette-primary-main: #1976d2;
    --mui-palette-primary-light: #42a5f5;
    --mui-palette-primary-dark: #1565c0;
    --mui-palette-primary-contrastText: #fff;
    /* ...other variables */
}

#background-video {
    width: 100%;
    height: 100%;
    position: fixed;
    object-fit: cover;
    z-index: 0;
}
#homepage-video {
    width: 100%;
    height: 100%;
    position: fixed;
    object-fit: cover;
}

#gallery-thumbnail {
    background-position: center;
    background-size: cover;
    width: 200px;
    height: 200px;
}

#login {
    position: relative;
    margin: 250px auto;
    padding: 20px;
    height: auto;
    width: auto;
    border-radius: 20px;
    box-shadow: 20px;
}
.app-background {
    background: rgb(33, 33, 33);
    background: linear-gradient(
        104deg,
        rgba(33, 33, 33, 1) 15%,
        rgba(100, 100, 100, 1) 63%,
        rgba(101, 101, 101, 1) 77%,
        rgba(106, 106, 106, 1) 88%,
        rgba(111, 111, 111, 1) 100%,
        rgba(122, 122, 122, 1) 100%,
        rgba(182, 182, 182, 1) 100%,
        rgba(223, 223, 223, 1) 100%,
        rgba(166, 166, 166, 1) 100%
    );
}

.nav-background {
    background: rgb(33, 33, 33);
    background: linear-gradient(
        104deg,
        rgba(33, 33, 33, 1) 15%,
        rgba(60, 60, 60, 1) 40%,
        rgba(98, 98, 98, 1) 60%,
        rgba(130, 130, 130, 1) 75%,
        rgba(183, 183, 183, 1) 85%,
        rgba(201, 201, 201, 1) 95%,
        rgba(223, 223, 223, 1) 100%
    );
}
.provision-wizard-button-background {
    background: rgb(85, 85, 85);
    background: radial-gradient(
        circle,
        rgba(85, 85, 85, 1) 0%,
        rgba(68, 68, 68, 1) 76%,
        rgba(51, 51, 51, 1) 100%
    );
}

.navigation-menu-toolbar-background {
    background: rgb(33, 33, 33);
    background: linear-gradient(
        104deg,
        rgba(33, 33, 33, 1) 23%,
        rgba(67, 85, 43, 1) 40%,
        rgba(68, 86, 43, 1) 60%,
        rgba(73, 94, 44, 1) 70%,
        rgba(83, 110, 47, 1) 75%,
        rgba(86, 114, 48, 1) 80%,
        rgba(91, 122, 49, 1) 85%,
        rgba(100, 137, 52, 1) 90%,
        rgba(113, 157, 56, 1) 95%,
        rgba(140, 198, 63, 0.75) 100%
    );
}

.universal-modal-background {
    background: rgb(33, 33, 33);
    background: linear-gradient(
        163deg,
        rgba(33, 33, 33, 1) 14%,
        rgba(60, 60, 60, 1) 36%,
        rgba(98, 98, 98, 1) 69%,
        rgba(130, 130, 130, 1) 82%,
        rgba(130, 130, 130, 1) 99%
    );
}
